import React, { FC } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";

export const Header: FC = () => {
  return (
    <header className="pt-3 pb-4">
      <div className="container-xl d-flex justify-content-between d-flex align-items-center">
        <img
          className="profile jb-profile"
          src="https://res.cloudinary.com/hv5cxagki/image/upload/c_scale,dpr_2,h_50,q_auto/v1720498039/burger_world/NEWLOGO_with_drop_shadow_eegqtt.png"
        />
        <div className="d-flex align-items-center">
          <a
            href="https://jup.ag/swap/SOL-BURGER_1eStxMfALAfbT2ctPV7yuk9pENB2BHTAqK5xDefpump"
            target="_blank"
            rel="noopener"
            className="me-3"
          >
            Buy $BURGER
          </a>
          <WalletMultiButton />
        </div>
      </div>
    </header>
  );
};
