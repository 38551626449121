import React, { FC } from "react";

export const Footer: FC = () => {
  return (
    <footer className="text-center">
      <div className="d-flex justify-content-around mb-4">
        <span className="d-sm-flex align-items-center border border-2 p-2">
          <div>POWERED BY</div>
          <img
            className="ms-sm-2"
            height="18"
            src="https://res.cloudinary.com/hv5cxagki/image/upload/c_scale,dpr_2,h_18,q_auto/v1/jeo_bodens/solanaLogo_gqows6.png"
          />
        </span>
      </div>
      <hr />
      This token is simply a meme coin with no intrinsic value or expectation of
      financial return.
      <br />© Copyright 2024. All rights reserved.
    </footer>
  );
};
