import React, { FC, useRef } from "react";
import { Header } from "./header";
import { Footer } from "./footer";
import { CandyMint } from "./candy_mint";

export const Home: FC = () => {
  const VideoRef = useRef(null);

  const onClick = async () => {
    if (VideoRef != null && VideoRef.current.paused) {
      VideoRef.current.play();
    } else {
      VideoRef.current.pause();
    }
  };

  return (
    <>
      <Header />
      <div id="home">
        <video autoPlay={true} muted={true} loop={true} id="banner-video">
          <source src="Animated Header 4.mp4" type="video/mp4" />
        </video>
{/*        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <div className="card text-center" id="mint-card">
                <h5>Them Goblin Boyz NFTs</h5>
                <div className="text-center">
                  <CandyMint />
                </div>
              </div>
            </div>
          </div>
        </div>*/}
        <section id="about">
          <div className="container-xl">
            <div className="row gx-5">
              <div className="col-12">
                <div className="page-heading">About $BURGER</div>
                <div className="contract-address">
                  CA: 1eStxMfALAfbT2ctPV7yuk9pENB2BHTAqK5xDefpump
                </div>
              </div>
            </div>
            <div className="row gx-5">
              <div className="col-sm-6 col-md-4 d-none d-sm-block align-content-center">
                <img
                  src="https://res.cloudinary.com/hv5cxagki/image/upload/v1720514512/burger_world/regular_c1khfj.png"
                  className="w-100"
                />
              </div>
              <div className="col-sm-6 col-md-4 align-content-center">
                <div id="tv-container" className="w-100" onClick={onClick}>
                  <video
                    autoPlay={false}
                    muted={false}
                    loop={false}
                    id="tv-video"
                    ref={VideoRef}
                    poster="https://res.cloudinary.com/hv5cxagki/image/upload/v1720656730/burger_world/67d0dc3517c9642f80f4fd516400c44f804a47da_ccp6uw.jpg"
                  >
                    <source src="IMG_2920.mp4" type="video/mp4" />
                  </video>
                  <img
                    src="https://res.cloudinary.com/hv5cxagki/image/upload/v1720533308/burger_world/newer_tv_no_drop_shadow_dyrbol.png"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="col-md-4 d-none d-md-block align-content-center">
                <img
                  src="https://res.cloudinary.com/hv5cxagki/image/upload/v1720514512/burger_world/regular_c1khfj.png"
                  className="w-100"
                />
              </div>
            </div>
            <div className="row gx-5">
              <div className="col-md-6 col-lg-5 align-content-center my-3 my-md-4 my-lg-5">
                <p>
                  There is no food product so sublime and dynamic as a burger.
                  Its cultural depth and relevance is unmatched. Chided by many
                  for its simplicity, yet embraced globally. Simplicity is the
                  burger's strength, but not a constraint.
                </p>
              </div>
              <div className="col-md-6 col-lg-7 align-content-center my-3 my-md-4 my-lg-5">
                <img
                  src="https://res.cloudinary.com/hv5cxagki/image/upload/v1720650211/burger_world/1_m4edgo.png"
                  className="w-100"
                />
              </div>
            </div>
            <div className="row gx-5">
              <div className="col-md-6 col-lg-5 order-md-last align-content-center my-3 my-md-4 my-lg-5">
                <p>
                  The burger is a shape shifter, a pleaser, and a phantom. The
                  burger can be complicated, and yet most consumed are simple,
                  meager, and loved.
                </p>
                <p>
                  We at Burger World offer a return to tradition. A return to
                  simpler times. A return to an appreciation of simplicity and
                  straight forwardness. No fuss, no gimmicks, just $BURGER.
                </p>
              </div>
              <div className="col-md-6 col-lg-7 align-content-center my-3 my-md-4 my-lg-5">
                <img
                  src="https://res.cloudinary.com/hv5cxagki/image/upload/v1720650211/burger_world/2_jzojmr.png"
                  className="w-100"
                />
              </div>
            </div>
            <div className="row gx-5">
              <div className="col-md-6 col-lg-5 align-content-center my-3 my-md-4 my-lg-5">
                <p>
                  We used to go to the burger joint to celebrate our wins with
                  the tee ball team.
                </p>
                <p>
                  Now it’s popping bottles, gold flaked steaks, and Louis XIII.
                </p>
                <p>Return to tradition. Burgers are for winners!</p>
                <div className="d-flex mt-3">
                  <a
                    href="https://t.me/burger_on_sol"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      src="https://res.cloudinary.com/hv5cxagki/image/upload/v1720502544/burger_world/Tremp_social_logos_-_red_trans-04_wm9ocr.png"
                      className="social-icon"
                    />
                  </a>
                  <a
                    href="https://x.com/burger_on_sol"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      src="https://res.cloudinary.com/hv5cxagki/image/upload/v1720502544/burger_world/Tremp_social_logos_-_red_trans-05_ttnt67.png"
                      className="social-icon"
                    />
                  </a>
                  <a
                    href="https://dexscreener.com/solana/FEM7YE25LaTzPtsSSk1st3eTqHq9TFWGWLpQrhi2NLFS"
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      src="https://res.cloudinary.com/hv5cxagki/image/upload/v1720502544/burger_world/Tremp_social_logos_-_red_trans-07_mt1xhp.png"
                      className="social-icon"
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-7 align-content-center my-3 my-md-4 my-lg-5">
                <img
                  src="https://res.cloudinary.com/hv5cxagki/image/upload/v1720650212/burger_world/3_d5orkn.png"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container-xl">
            <div className="page-heading">Roadmap</div>
            <div className="row">
              <div className="col-12">
                <img
                  src="https://res.cloudinary.com/hv5cxagki/image/upload/v1720745072/burger_world/Roadmap_zhze9m.png"
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
